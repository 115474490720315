import {
  parse as parseQueryString,
  stringify as stringifyQueryString
} from 'query-string'
import { cloneDeep } from 'lodash'

export const getQueryString = (params = {}) => {
  return cloneDeep(
    stringifyQueryString(cloneDeep(params), {
      arrayFormat: 'bracket'
    })
  )
}

export const getQueryStringObject = (params = {}) => {
  return cloneDeep(
    parseQueryString(cloneDeep(params), {
      arrayFormat: 'bracket'
    })
  )
}

export const updateURLParams = (params = {}, history) => {
  if (!history || !history.push) return
  history.push({
    search: getQueryString(params)
  })
}

export const updateURL = (pathname = '', params = {}, history) => {
  if (!history || !history.push) return
  history.push({
    pathname,
    search: getQueryString(params)
  })
}
